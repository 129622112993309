import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "state-and-city-names"
    }}>{`State and City Names`}</h1>
    <p>{`Spell out state names in running text.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`This company is based in Pleasanton, California.`}</li>
      </ul>
    </Do>
    <p>{`When a state name is used alone, without a city, spell out the name of the state.`}</p>
    <p>{`Use two-letter Postal Service abbreviations in tables, lists, mailing addresses, and displayed quote
attributions.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Toodles Magee`}<br parentName="li"></br>
          {`6230 Stoneridge Mall Road`}<br parentName="li"></br>
          {`Pleasanton, CA 94588`}</li>
      </ul>
    </Do>
    <p>{`When referring to the U.S. capital, use Washington, D.C. (note comma and periods).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      